import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description:
      "Ich bin ein Junior Full-Stack-Entwickler und bringe eine positive Einstellung in jedes Projekt ein. Egal, ob es sich um eine App, Software, ein Spiel oder eine Website/Webanwendung handelt, meine einzigartige Kombination aus Marketingwissen und Designverständnis verleiht Ihrem nächsten Projekt ein professionelles, poliertes Aussehen, das bei Ihrem Publikum gut ankommt. Ich liebe es, neue und innovative Lösungen in Design und Programmierung zu finden und freue mich immer über Feedback.",
    highlights: {
      bullets: [
        "Full-Stack-Web- und Mobile-Entwicklung",
        "Interaktives Front-End gemäß dem Design",
        "JAVASCRIPT/REACT.JS",
        "PHP/Laravel",
        "Problemlösung/Design Thinking",
        "Datenbankverwaltung",
      ],
      heading: "Hier sind einige Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={"Über mich"} subHeading={"Warum sollten Sie mich wählen?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              >
                {" "}
                Kontaktieren Sie mich{" "}
              </button>
            <a href="Lebenslauf_Abdelali_Malki.pdf" download="Lebenslauf_Abdelali_Malki.pdf">
              <button className="btn highlighted-btn">Lebenslauf anzeigen </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
