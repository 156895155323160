import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Bildung", logoSrc: "education.svg" },
    { label: "Berufserfahrung", logoSrc: "work-history.svg" },
    { label: "Programmierfähigkeiten", logoSrc: "programming-skills.svg" },
    { label: "Projekte", logoSrc: "projects.svg" },
    { label: "Interessen", logoSrc: "interests.svg" },
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "React JS", ratingPercentage: 85 },
    { skill: "PHP", ratingPercentage: 85 },
    { skill: "Laravel", ratingPercentage: 89 },
    { skill: "Andriod Studio", ratingPercentage: 50 },
    { skill: "Java", ratingPercentage: 60 },
    { skill: "HTML", ratingPercentage: 80 },
    { skill: "CSS", ratingPercentage: 80 },
  ];

  const projectsDetails = [
    {
      title: "abdelalimalki.com",
      duration: { Date: "2024" },
      description:
        "Eine persönliche Portfolio-Website, um alle meine Details und Projekte an einem Ort zu präsentieren.",
      subHeading: "Technologies Used: React JS, Bootsrap",
    },
    {
      title: "gamerstoremaroc.com ",
      duration: { Date: "2023" },
      description:
        "Online-E-Commerce-Website zum Präsentieren und Verkaufen von Computern und Zubehör in Marokko",
      subHeading:
        "Technologies Used: WordPress ,Wocommerce, Html&Css, PHP, MysQl",
    },
    {
      title: "Wallpaper-App ",
      duration: { Date: "01/06 /2022" },
      description:
        "Entwickelte eine mobile Anwendung für hochauflösende Wallpapers in verschiedenen Kategorien mit Funktionen wie täglichen Updates, einfacher Navigation und Favoriten-Speicherung. Erfolgreiche Veröffentlichung im Google Play Store ",
      subHeading:
        "Technologies Used:  Java, Andriod Studio ",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Solicode - Tanger , Marokko"}
        subHeading={"Qualifizierung Training (Bootcamp) in der mobilen Entwicklung"}
        fromDate={"2022"}
        toDate={"2023"}
      />

      <ResumeHeading
        heading={"Brevet du Technicien Supérieur Meknès (BTS) , Marokko"}
        subHeading={"Technikerdiplom in der Entwicklung von Informationssystemen"}
        fromDate={"2020"}
        toDate={"2022"}
      />
      <ResumeHeading
        heading={"Lycée Qualifiant Moulay Ismail Meknès, Marokko"}
        subHeading={"Abitur im Bereich der Elektrotechnik "}
        fromDate={"2019"}
        toDate={"2020"}
      />
    </div>,

    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
      <ResumeHeading
          heading={"Brevet du Technicien Supérieur , Marokko"}
          subHeading={"Studienabschluss Projekt in BTS Meknès ,Marokko (3 Monate)"}
          fromDate={"01/03/2022"}
          toDate={"30/05/2022"}/>
          <span className="resume-description-text">
          <b>Projektbeschreibung:</b> Design und Entwicklung einer Webanwendung
          Abwesenheitsmanagement<br/>
          <b>Tools:</b> Html&Css, bootstrap, Javascript, PHP Laravel
          </span>
        <ResumeHeading
          heading={"École Nationale d'Agriculture ,Marokko"}
          subHeading={"Praktikum an der Nationalen Landwirtschaftsschule ,Marokko ( 2 Monate)"}
          fromDate={"01/07/2021"}
          toDate={"30/08/2021"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
          <b>Projektbeschreibung:</b> Design und Entwicklung einer Webanwendung für die Internatsverwaltung<br/>
          <b>Tools:</b> Asp.net, VB.NET, SQL Server, Merise,Illustrator 
          </span>
          
        </div>
          <br />
        </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
        />
      ))}
    </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
     
      <ResumeHeading
        heading="Beat-Produzent"
        description="ich leidenschaftlicher Beat-Produzent. Ich kreiere Musik, indem ich einzigartige Beats erschaffe, mit Klängen experimentiere und verschiedene Genres mische. Beat-Produktion lässt mich meine Kreativität ausleben und meine technischen Fähigkeiten in der Klangtechnik verbessern."
      />
      <ResumeHeading
        heading="Fitness"
        description="Meine Leidenschaft neben der Entwicklung innovativer Anwendungen ist Fitness. Es hilft mir, Disziplin, Fokus und Energie zu bewahren, was meine Arbeit als Entwickler optimal ergänzt. Für mich ist Fitness eine Möglichkeit, mich selbst herauszufordern und motiviert zu bleiben."
      />
       <ResumeHeading
        heading="Reisen"
        description="Reisen ist für mich eine Möglichkeit, neue Kulturen zu entdecken, meine Perspektiven zu erweitern und mich von verschiedenen Orten und Menschen inspirieren zu lassen. Es hilft mir, offener und flexibler zu denken, was auch in meiner beruflichen Arbeit als Entwickler von Vorteil ist."
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Mein Lebenslauf"} subHeading={"Meine formalen Biografie-Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
