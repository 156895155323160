import React from "react";
import Typical from "react-typical";
import "./Profile.css";
import ScrollService from "../../../utilities/ScrollService";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
          <div className="colz-icon">
              <a href="https://www.linkedin.com/in/abdelali-malki-2052b6257/">
                <i className="fa fa-linkedin" />
              </a>
              <a href="https://github.com/abdelali-malki/">
                <i className="fa fa-github" />
              </a>
              <a href="https://www.instagram.com/malki.ae/">
                <i className="fa fa-instagram" />
              </a>
              <a href="mailto:kontakt@abdelalimalki.com">
                <i className="fa fa-envelope" />
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hallo, ich bin <span className="highlighted-text">Abdelali MALKI</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    "Willkommen in meinem digitalen Zuhause 🙌🏻",
                    1500,
                    "Junior Full-Stack-Entwickler💻",
                    1000,
                  ]}
                />
              </h1>
            </span>
            <span className="profile-role-tagline">
            Geschick im Entwickeln von Anwendungen mit Frontend- und Backend-Operationen.
            </span>
          </div>

          <div className="profile-options">
            <button className="btn primary-btn"
            onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            > Kontaktieren Sie mich </button>
            <a href="Lebenslauf_Abdelali_Malki.pdf" download="Lebenslauf_Abdelali_Malki.pdf">
              <button className="btn highlighted-btn">Lebenslauf anzeigen </button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}